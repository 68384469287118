import React, { useEffect } from 'react';
import './App.css';
import AOS from 'aos'
import "aos/dist/aos.css";
import TransitionSwitch from 'react-router-transition-switch'
import Fader from 'react-fader'
import JSMBar from './components/JSMBar'
import JSMFooter from './components/JSMFooter'
import Home from './components/Home'
import Services from './components/Services'
import About from './components/About'
import Testimonials from './components/Testimonials'
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      delay: 300
    })
  })

  return (
    <div style={{position: 'relative', minHeight: '100vh'}}>
      <div style={{paddingBottom: '3rem'}}>
        <Router>
          <JSMBar/>
            <TransitionSwitch component={Fader}>
              <Route path="/about" component={About} />
              <Route path="/services" component={Services} />
              <Route path="/testimonials" component={Testimonials} />
              <Route path="/" component={Home} />
            </TransitionSwitch>
          <JSMFooter/>
        </Router>
      </div>
    </div>
  );
}

export default App;
