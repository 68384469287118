import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Event from './Event'
import EventList from './EventList'

import Conservatory from './static/conservatory.jpg'
import Window from './static/window.jfif'
import Driveway from './static/driveway.jpg'
import Gutter from './static/guttering.jpg'
import Solar from './static/solar.jfif'
import Cladding from './static/cladding.jfif'
const useStyles = makeStyles(theme => ({
    logoContainer: {
        fontFamily: 'Gilroy',
        width: '100%',
        margin: '0 auto',
        fontSize: '4.5em',
        color: '#141e30',
        textAlign: 'center',
        position: 'relative',
    },
    logoSpan: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    tagline: {
        fontFamily: 'GilroyLight',
        margin: '0 auto',
        fontSize: '1.5em',
        color: '#141e30',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem'
        }
    },
}))

export default function Services() {
    const classes = useStyles()

    return (
        <div className={classes.rootContainer}>
            <header className={classes.logoContainer}>
                <span className={classes.logoSpan} data-aos="fade-down">OUR CLEANING SERVICES</span>
            </header>
            <br/>
            <div className={classes.serviceContainer}>
                <Grid 
                container
                spacing={0}
                alignItems="center"
                justify="center"
                >
                <Grid item xs={10}>
                    <EventList
                    title="Areas Covered"
                    text="We cover a wide range of areas within Essex."
                    />
                    <Event
                    title="Window Cleaning"
                    image={Window}
                    text="Our prices include cleaning of windows, doors, ledges and frames. We can offer this service on a frequency to suit your needs."
                    direction="row"
                    />
                    <Event
                    title="Conservatory Cleaning"
                    image={Conservatory}
                    text="We provide a thorough clean to your conservatory glass inside and out. We can offer this service on a frequency to suit your needs."
                    direction="row-reverse"
                    />
                    <Event
                    title="Patios & Driveway Cleaning"
                    image={Driveway}
                    text="Powerful pressure washing of your driveways and patios. We can offer this service on a frequency to suit your needs."
                    direction="row"
                    />
                    <Event
                    title="Fascias, Soffits & Cladding Cleaning"
                    image={Cladding}
                    text="All Fascias, Soffits and Cladding cleaned to a high standard. We can offer this service on a frequency to suit your needs."
                    direction="row-reverse"
                    />
                    <Event
                    title="Guttering Cleaning"
                    image={Gutter}
                    text="All guttering unblocked and cleaned. We can offer this service on a frequency to suit your needs."
                    direction="row"
                    />
                    <Event
                    title="Roofs & Solar Panel Cleaning"
                    image={Solar}
                    text="We'll even clean your roof, along with any solar panels on top! We can offer this service on a frequency to suit your needs."
                    direction="row-reverse"
                    />
                    <br/>
                </Grid>
            </Grid>
            </div>
        </div>
    )
}