import React, {useState} from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import MailOutline from '@material-ui/icons/MailOutline'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider'
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import InfoIcon from '@material-ui/icons/Info'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    appBar: {
      background: `#141e30`,
      background: `-webkit-linear-gradient(to right, #141e30, #243b55)`,
      background: `linear-gradient(to right, #141e30, #243b55)`,
      postion: 'relative'
    },
    drawer: {
        background: `#141e30`,
        postion: 'relative',
        color: 'white',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      
    },
    title: {
      flexGrow: 1,
      fontFamily: 'GilroyLight, sans-serif'
    },
    button: {
        fontFamily: 'GilroyLight, sans-serif',
        marginLeft: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontFamily: 'GilroyLight',
    },
    icon: {
        color: 'white'
    },
    box: {
        float: 'right'
    },
    divider: {
      borderTop: '1px solid white'
    }
  }));

function AveryBar () {


    const sideList = side => (
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer}
        >
          <List>
              <Link to="/" className={classes.link}>
                <ListItem button>
                  <ListItemIcon> <HomeIcon className={classes.icon} /></ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>
              <Link to="/about" className={classes.link}>
                <ListItem button>
                  <ListItemIcon> <InfoIcon className={classes.icon} /> </ListItemIcon>
                  <ListItemText primary="About" />
                </ListItem>
              </Link>
              <Divider className={classes.divider}/>
              <Link to="/services" className={classes.link}>
                <ListItem button>
                  <ListItemIcon> <WorkIcon className={classes.icon} /> </ListItemIcon>
                  <ListItemText primary="Services" />
                </ListItem>
              </Link>
              <Link to="/testimonials" className={classes.link}>
                <ListItem button>
                  <ListItemIcon> <ChatBubbleOutlineIcon className={classes.icon} /></ListItemIcon>
                  <ListItemText primary="Testimonials" />
                </ListItem>
              </Link>
          </List>
          <Divider className={classes.divider} />
            <a href="https://www.facebook.com/Jsm-Services-246043109208965/" className={classes.link} target="_blank" rel="noopener noreferrer">
            <ListItem button>
                <ListItemIcon> <FacebookIcon className={classes.icon} /></ListItemIcon>
                <ListItemText primary="Facebook Page" />
              </ListItem>
            </a>
            <a href="tel:01268698626" className={classes.link} target="_blank" rel="noopener noreferrer">
            <ListItem button>
                <ListItemIcon> <PhoneInTalkIcon className={classes.icon} /></ListItemIcon>
                <ListItemText primary="01268 698626" />
              </ListItem>
            </a>
            <a href="tel:07709201906" className={classes.link} target="_blank" rel="noopener noreferrer">
            <ListItem button>
                <ListItemIcon> <PhoneIphoneIcon className={classes.icon} /></ListItemIcon>
                <ListItemText primary="07709 201906" />
              </ListItem>
            </a>
            <a href="mailto:jsmwindows@outlook.com" className={classes.link} target="_blank" rel="noopener noreferrer">
            <ListItem button>
                <ListItemIcon> <MailOutline className={classes.icon} /></ListItemIcon>
                <ListItemText primary="jsmwindows@outlook.com" />
              </ListItem>
            </a>
        </div>
    );
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false)

    function toggleDrawer() {
        setDrawer(!drawer)
    }

    return (
        <div className={classes.root}>
            <AppBar position="static"  className={classes.appBar}>
                <Toolbar>
                <IconButton onClick={toggleDrawer}  edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    <Link className={classes.link} to="/">JSM Services</Link>
                </Typography>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer  classes={{paper: classes.drawer}} disableDiscovery="true" disableSwipeToOpen="true" open={drawer} onClose={toggleDrawer} anchor="left">
                {sideList('left')}
            </SwipeableDrawer>
    </div>
    )
}

export default AveryBar;