import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import Fader from 'react-fader'

const useStyles = makeStyles(theme => ({
    logoContainer: {
        fontFamily: 'Gilroy',
        width: '100%',
        margin: '0 auto',
        fontSize: '4.5em',
        color: '#141e30',
        textAlign: 'center',
        position: 'relative',
    },
    logoSpan: {
        display: 'inline-block',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem'
        }
    },
    tagline: {
        fontFamily: 'GilroyLight',
        margin: '0 auto',
        fontSize: '3em',
        color: '#141e30',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: '2rem'
        }
    },
    nameContainer: {
    },
    name: {
        float: 'right',
        margin: '20px',
        fontFamily: 'GilroyLight',
        fontWeight: 'bold',
        fontSize: '1.4rem'
    },
    button: {
        margin: '20px',
        backgroundColor: '#141e30',
        color: 'white'
    },
    text: {
        margin: '20px',
        fontFamily: 'GilroyLight',
        fontSize: '1.3rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem'
    }
    },
    paper: {
        borderLeft: '7px solid #141e30',
        boxShadow: `0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`
    }

}));

export default function Testimonials() {

    const testimonialArray = [
        {
            name: "Lilas Spa - Chalkwell",
            text: "James is reliable and I can always tell when he has been and cleaned our windows. We are situated in a high traffic area so the windows need to be cleaned regularly, having them done professionally is one less thing I have to worry about for my business. "
        },
        {
            name: "Suttons Carpets and Furniture Store - Rayleigh",
            text: "We have been using JSM Services for many years. Always reliable, always give us a great service at a very competitive price. We highly recommend and appreciate what they do."
        },
        {
            name: "Jo Weingarten - Leigh on Sea",
            text: "Reliable and friendly service, would definitely recommend James and his team. Used his serivice for 5 years now! 5 Stars."
        },
        {
            name: "John & Peggy - Rayleigh",
            text: "We have used JSM for a number of years now, and have always found them to be reliable and do a professional job."
        },
        {
            name: 'Colin Philpott - South Woodham Ferrers',
            text: "James has been our window cleaner for some years now, he's a great guy, friendly and reliable and always does a great job. I have a lot of windows and could not do without him. He also does my gutters and fascias every so often, keeping them clean and working. He also texts me before he is due to come, which enables me to ensure the side gate is open on the right days, which improves security. I would always recommend him."
        },
        {
            name: "Marlene - Canvey Island",
            text: `James has been cleaning my windows for 8 years. He always texts me to tell me what day he is coming. He is friendly, trustworthy and always does a good job at a reasonable cost. He also does my soffits and gutters when I request it. Wouldn't use anybody else.`
        },
        {
            name: "David and Lesley - Benfleet",
            text: "We would recommend James - He is very reliable, and always does a thorough job of cleaning the windows."
        },
        {
            name: 'Ann - Southend',
            text: "I can thoroughly recommend James. He has cleaned our windows regularly for many years, is reliable and does an excellent job."
        },
        {
            name: "Vicky - Bowers Gifford",
            text: "Fantastic service, always thorough. I have dogs and I can always trust JSM to ensure the gates are locked when they are finished. Windows and frames always look lovely and clean when they are finished. Thoroughly recommend them."
        },
        {
            name: "Dawn Hobson - Ramsden Bellhouse",
            text: "James provides a great service, very thorough and very reliable - would highly recommend."
        },
        {
            name: "Pauline - Basildon",
            text: "I have been using JSM services to clean my windows for the last 6 years. They provide a professional and friendly service, notifying you a day or so before they are due. I would definitely, and in fact already have, recommended james to neighbours and friends who are also pleased with the service."
        },
        {
            name: "Andria and Keith - Basildon",
            text: "Excellent professional service, always arrives cheerful and smiling. James lets us know by text the day before arrival that our window cleaning is due which is very useful for planning the day. We have also used the gutter cleaning service, again this was accomplished to a high standard and at a reasonable price compared to other companies in the area. WOuld recommend the service to friends and neighbours all the time."
        },
        {
            name: "Lesley - Hadleigh",
            text: "The work James and his staff have done for us over the years has always been of a very high standard and he is very pleasant and helpful to deal with."
        },
        {
            name: "Susan - Rayleigh",
            text: "James has provided us with a reliable window cleaning service for many years now, and is always polite and friendly."
        }
    ]
    const classes = useStyles();
    const [textIndex, setTextIndex] = useState(0)
    const [name, setName] = useState("Kevin - Basildon")
    const [text, setText] = useState("James is a reliable and professional window cleaner with a high quality of work. Unlike previous cleaners I have had, he also ensures that windowsills are cleaned to a high standard. Also very easy to deal with.")

    function handleClick() {
        setTextIndex(textIndex + 1)
        if (textIndex >= 13) {setTextIndex(0)}

        setName(testimonialArray[textIndex].name)
        setText(testimonialArray[textIndex].text)
    }

    return(
        <div>
            <header className={classes.logoContainer}>
                <span className={classes.logoSpan} data-aos="fade-down">TESTIMONIALS</span>
            </header>
            <br/><br/>
            <Grid
            container
            spacing
            alignItems="center"
            justify="center"
            >
                <Grid item xs={10}>
                    <Paper square className={classes.paper} data-aos="fade-up">
                        <Fader>
                            <Typography className={classes.text}>“{text}”</Typography>
                            <div className={classes.nameContainer}>
                                <Typography className={classes.name}>{name}</Typography>
                                <Button variant="contained" size="large" className={classes.button} onClick={() => handleClick()}>Next</Button> 
                            </div>
                        </Fader>
                    </Paper>
                </Grid>
            </Grid>
            <br/><br/>
        </div>
    )
}

