import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom'

const useStyles = makeStyles(theme =>({
    root: {
      flexGrow: 1,
      textAlign: 'center',
    },
    outer: {
      marginTop: '10%',
      padding: '15px',
      textAlign: 'center',
    },
    bio: {
      fontFamily: 'GilroyLight',
      fontSize: '1.05em',
      color: 'rgba(0, 0, 0, 0.67)'
    },
    headers: {
      fontFamily: 'GilroyLight',
      fontSize: '1.5em'
    },
    buttonLink: {
      textDecoration: 'none'
    },
    button: {
      marginTop: '10px',
      backgroundColor: '#141e30',
      color: 'white',
      textDecoration: 'none'
  },
  circle: {
    height: "75px",
    width: "75px",
    backgroundColor: '#d8d8d8',
    borderRadius: '50%',
    display: 'inline-block',
    fontSize: '50px'
  },
  icon: {
    lineHeight: '75px',
    height: '75px',
    color: '#141e30'
  },
  link: {
    textDecoration: 'none'
  }
}))

function TeamCard(props) {
    const classes=useStyles()
    return (
    <div className={classes.outer} data-aos={props.animation} data-aos-delay={props.delay} data-aos-easing="ease-out-cubic">
      <Card className={classes.root}>
        <CardMedia
        component="img"
        height={props.imgHeight}
        className={classes.media}
        image={props.image}
        title={props.name}
        />
        <CardContent>
          <Typography className={classes.headers} variant="h5" component="h2">
            {props.name} 
          </Typography>
          <Typography className={classes.headers} variant="h6" color="textSecondary">
            {props.role}
          </Typography>
          <hr/>
          <Typography className={classes.bio} variant="body2" component="p">
            {props.bio}
          </Typography>
          {props.button && 
          <Link className={classes.link} to={props.buttonLink}>
            <Button className={classes.button} variant="contained">{props.buttonText}</Button>
          </Link>}
        </CardContent>
      </Card>
    </div>
    )
}

export default TeamCard;