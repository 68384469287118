import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme =>({
    root: {
        textAlign: 'center'
    },
    title: {
        fontSize: '1.8rem',
        fontFamily: 'Gilroy, sans-serif',
        textAlign: 'center',
    },
    media: {
        '@media (max-width: 960px)': {
            height: '350px',
        },
        marginRight: '15px',
        '@media (min-width: 450px)': {
            marginRight: '0px',
            marginTop: '15px',
            [theme.breakpoints.up('md')]: {
                marginLeft: '35px'
            }
        },
    
        
    },
    reverseMedia: {
        '@media (max-width: 960px)': {
            height: '350px',
        },
        '@media (min-width: 450px)': {
            margin: '0px',
            marginTop: '15px'
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '-35px',
            marginBottom: '35px'
        }

    },
    article: {
        fontSize: '1.1rem',
        fontFamily: 'GilroyLight',
        textAlign: 'centered',
        padding: '10px',
    },
    list: {
        margin: '20px',
        fontFamily: 'GilroyLight'
    },
    borderRight: {
        [theme.breakpoints.up("md")]: {
            borderRight: '1px solid rgba(0,0,0,0.87)'
        }
    },
    borderLeft: {
        [theme.breakpoints.up("md")]: {
            borderLeft: '1px solid rgba(0,0,0,8.87)'
        }
    }
}))
function Event(props) {
    const classes = useStyles();
  return (
    <div className={classes.root}>
        <Paper square>
            <Grid
            className={classes.main}
            container
            spacing={0}
            alignItems="stretch"
            justify="center"
            direction={props.direction}
            >
                <Grid item md={6} className={classes.borderRight}>
                    <Typography className={classes.title}>
                        Buildings Covered
                    </Typography>
                    <hr/>
                    <Typography className={classes.article}>
                        We clean many buildings, both commericial and residential. <br/>
                        <strong>
                        Nursing Homes &ensp; Bungalows &ensp; Flats <br/>
                        Commercial Properties &ensp; Shops &ensp; Schools <br/>
                        Hotels &ensp; Hospitals
                        </strong>
                    </Typography>
                    <br/><br/>
                </Grid>
                <Grid item md={6} className={classes.borderLeft}>
                    <Typography className={classes.title}>
                        Areas Covered
                    </Typography>
                    <hr/>
                    <Typography className={classes.article}>
                        We cover a wide range of areas within Essex. <br/>
                        <strong>
                        Basildon &ensp; Benfleet &ensp; Billericay <br/>
                        Canvey Island &ensp; Chelmsford &ensp; Rayleigh <br/>
                        Southend &ensp; Wickford &ensp;<br/> South Woodham Ferrers <br/>
                         &ensp;
                        </strong>
                    </Typography>
                    <br/>
                </Grid>
            </Grid>
        </Paper>
    </div>
 )}
export default Event