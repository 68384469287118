import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom'
import Solar from './static/solar.jfif'
import Event from './Event'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
    logoContainer: {
        fontFamily: 'Gilroy',
        width: '100%',
        margin: '0 auto',
        fontSize: '4.5em',
        color: '#141e30',
        textAlign: 'center',
        position: 'relative',
    },
    logoSpan: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    tagline: {
        fontFamily: 'GilroyLight',
        margin: '0 auto',
        fontSize: '2.5em',
        color: '#141e30',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: '2rem'
        }
    },
    reason: {
        fontFamily: 'GilroyLight',
        fontSize: '1.7rem',
        color: '#141e30',
        textAlign: 'center',

    },
    paper: {
    },
    paperText: {
        padding: '20px',
        fontFamily: 'GilroyLight',
        fontSize: '1.2rem',
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    }

}));

export default function About() {

    const isPhone = useMediaQuery('(max-width: 600px')

    let middleFade = "fade-up"

    if (isPhone) {middleFade = "fade"}
    const classes = useStyles();

    return(
        <div>
            <div className={classes.heroContainer}>
                <header className={classes.logoContainer}>
                    <span className={classes.logoSpan} data-aos="fade-down">ABOUT</span>
                </header>
                <br/><br/>
            </div>
            <Grid
            container
            spacing={0}
            alignItems="stretch"
            justify="center"
            >
                <Grid item sm={4}>
                    <Typography className={classes.reason} data-aos={middleFade} data-aos-delay="700">Established in 2004<br/></Typography>
                </Grid>
                <Grid item sm={4}>
                    <Typography className={classes.reason} data-aos={middleFade} data-aos-delay="700">&nbsp;Fully Insured&nbsp;<br/></Typography>
                </Grid>
                <Grid item sm={4}>
                    <Typography className={classes.reason} data-aos={middleFade} data-aos-delay="700">Friendly and Reliable Service<br/></Typography>
                </Grid>
            </Grid>
            <br/>
            <Typography className={classes.reason} data-aos="fade-up" data-aos-delay="900">These are just some of the reasons we're the cleaning company you've been looking for.</Typography>
            <br/>
            <Grid
            container
            spacing={0}
            alignItems="stretch"
            justify="center"
            >
                <Grid item xs={10} data-aos="fade-up" data-aos-delay="1200">
                    <Event
                    title="Our Mission"
                    image={Solar}
                    text={
                        <div> We are a well established company offering a wide range of <Link className={classes.link} to="/services">services</Link> to suit all your exterior cleaning needs. <br/>
                            At JSM Services, we're here to help you achieve and maintain that perfect home exterior. <br/>
                            From driveways and patios to gutters and conservatories, our team have the expertise to handle it all. We pride ourselves on our excellent track record
                            of delivering a quality service, every time. For any queries, do not hestiate to <Link className={classes.link} to="/contact">contact us</Link>.<br/></div>
                    }
                    direction="row-reverse"
                    />
                </Grid>
            </Grid>
            <br/><br/>
        </div>
    )
}

