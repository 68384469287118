import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    height: '3rem',
    background: `#141e30`,
    background: `-webkit-linear-gradient(to right, #141e30, #243b55)`,
    background: `linear-gradient(to right, #141e30, #243b55)`,
  },
  author: {
    fontFamily: 'Inconsolata',
    textAlign: 'center',
    marginTop: '0.2rem',
    textDecoration: 'none',
    color: 'white',
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      lineHeight: '3rem',
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: 'right'
    }
    
  },
  date: {
    bottom: 0,
    left: '5px',
    position: 'absolute',
    fontWeight: 'bold',
    fontFamily: 'GilroyLight',
    color: 'white',
    textDecoration: 'none'
  },
  link: {
    color: 'white',
    fontFamily: 'GilroyLight',
    textDecoration: 'none'
  },
  authorLink: {
    color: 'white',
    fontFamily: 'Inconsolata',
    textDecoration: 'none'
  }
}));

export default function JSMFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container maxWidth="m">
          <Typography className={classes.author} variant="body1">Created by <a target="_blank" rel="noopener noreferrer" className={classes.authorLink} href="https://jaack.dev">jaack.dev</a></Typography>
          <Typography className={classes.date} variant="body2">
              <a href="tel:07709201906" className={classes.link}>07709 201906</a>
              <br/>
              <a href="mailto:jsmwindows@outlook.com" className={classes.link}>jsmwindows@outlook.com</a>
          </Typography>
        </Container>
      </footer>
    </div>
  );
}