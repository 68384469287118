import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CallCard from './CallCard'
import Fader from 'react-fader'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Service from './static/window.jfif'
import Driveway from './static/driveway.jpg'
import Solar from './static/solar.jfif'

const useStyles = makeStyles(theme => ({
    logoContainer: {
        fontFamily: 'Gilroy',
        width: '100%',
        margin: '0 auto',
        fontSize: '4.5em',
        color: '#141e30',
        textAlign: 'center',
        position: 'relative',
    },
    logoSpan: {
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    tagline: {
        fontFamily: 'GilroyLight',
        margin: '0 auto',
        fontSize: '3em',
        color: '#141e30',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: '2rem'
        }
    },
    tagline2: {
        fontFamily: 'GilroyLight',
        margin: '0 auto',
        fontSize: '2em',
        color: '#141e30',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.5rem'
        }
    },
    contactLink: {
        color: '#141e30',
        textDecoration: 'none',
    }

}));

export default function Home() {

    const areaArray = [
        "Basildon",
        "Benfleet",
        "Billericay",
        "Canvey Island",
        "Chelmsford",
        "Leigh-on-Sea",
        "Ramsden Bellhouse",
        "Rayleigh",
        "South Woodham Ferrers",
        "Southend-on-Sea",
        "Thorpe Bay",
        "Wickford"
    ]
    const classes = useStyles();
    const [area, setArea] = useState("Essex")
    const [areaIndex, setAreaIndex] = useState(0)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (areaIndex < 11) {setAreaIndex(areaIndex + 1)}
            else {setAreaIndex(0)}
            setArea(areaArray[areaIndex])
        }, 1250)
        return () => clearTimeout(timer)
    })

    const isPhone = useMediaQuery('(max-width: 600px')

    let middleFade = "fade-up"

    if (isPhone) {middleFade = "fade"}
    return(
        <div className={classes.heroContainer}>
            <header className={classes.logoContainer}>
                <span className={classes.logoSpan} data-aos="fade-down">JSM SERVICES</span>
            </header>
            <div className={classes.tagline} data-aos="fade-up">Window Cleaning & Exterior Cleaning <br/> Residential & Commercial <Fader fadeInTransitionDuration="400" fadeOutTransitionDuration="400">{area}</Fader></div>
            <div className={classes.tagline2} data-aos="fade-up"><a className={classes.contactLink} href="tel:07709201906">07709 201906</a></div>
            <div className={classes.tagline2} data-aos="fade-up"><a className={classes.contactLink} href="mailto:jsmwindows@outlook.com">jsmwindows@outlook.com</a></div>

            <Grid
            container
            spacing
            alignItems="center"
            justify="center"
            >
                <Grid item xs={10}>
                    <Grid
                    container
                    spacing={5}
                    alignItems="stretch"
                    justify="center"
                    >
                        <Grid item md={4} data-aos={middleFade} data-aos-delay="350">
                            <CallCard
                            image={Driveway}
                            name="Dependable"
                            bio="We're trusted by clients both near and far."
                            button
                            buttonText="Testimonials"
                            buttonLink="/testimonials"
                            />
                        </Grid>
                        <Grid item md={4} data-aos={middleFade} data-aos-delay="350">
                            <CallCard
                            image={Service}
                            name="Driven"
                            bio="We constantly strive to deliver a quality service."
                            button
                            buttonText="About"
                            buttonLink="/about"
                            />
                        </Grid>
                        <Grid item md={4} data-aos={middleFade} data-aos-delay="350">
                            <CallCard
                            image={Solar}
                            name="Diverse"
                            bio="We offer a wide range of services to suit your needs."
                            button
                            buttonText="Services"
                            buttonLink="/services"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
        </div>
    )
}

